import { useQuery } from "@apollo/client";
import { Flex, LoadingSpinner } from "@govlaunch/web";
import React from "react";
import GetIsOnboardingActive from "~/lib/queries/GetIsOnboardingActive";
import GetOnboarding from "~/lib/queries/GetOnboarding";
import {
  IGetIsOnboardingActiveQuery,
  IGetIsOnboardingActiveQueryVariables,
} from "~/lib/queries/__generated__/GetIsOnboardingActive.generated";
import { IOnboardingQuery, IOnboardingQueryVariables } from "~/lib/queries/__generated__/GetOnboarding.generated";
import Redirect from "~/lib/route/Redirect";
import LandingPage from "~/pages/landingPage";
import NavbarLayout from "~/src/components/navbar/NavbarLayout";
import isOnboarding from "~/src/components/onboarding/isOnboarding";

interface IIndexPageProps {
  userId: any;
}

export default function IndexPage({ userId }: IIndexPageProps) {
  const { data, loading } = useQuery<IGetIsOnboardingActiveQuery, IGetIsOnboardingActiveQueryVariables>(
    GetIsOnboardingActive,
    {
      fetchPolicy: "network-only",
    },
  );

  const { data: userOnboarding, loading: userOnboardingLoading } = useQuery<
    IOnboardingQuery,
    IOnboardingQueryVariables
  >(GetOnboarding, {
    variables: {
      userId: userId,
    },
    fetchPolicy: "network-only",
  });

  if (loading || userOnboardingLoading) {
    <NavbarLayout pb={0}>
      <Flex justify="center">
        <LoadingSpinner />
      </Flex>
    </NavbarLayout>;
  }

  if (userOnboarding && data && isOnboarding(userOnboarding.onboarding, data.getIsOnboardingActive)) {
    return <Redirect to="/onboarding" />;
  }

  return <LandingPage />;
}
