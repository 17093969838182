import React from "react";
import Router from "next/router";
import ServerResponseContext from "~/lib/route/ServerResponseContext";

interface IRedirect {
  to: string;
}

const Redirect: React.FunctionComponent<IRedirect> = ({ to }) => {
  return (
    <ServerResponseContext.Consumer>
      {response => {
        if (response) {
          response.writeHead(303, {
            Location: to,
          });

          response.end();
          return null;
        }

        if (process.browser) {
          Router.replace(to);
        }

        return null;
      }}
    </ServerResponseContext.Consumer>
  );
};

export default Redirect;
