import { IOnboardingQuery } from "~/lib/queries/__generated__/GetOnboarding.generated";
import { IProcessStatusType } from "~/types/types";

export default function isOnboarding(
  userOnboarding: IOnboardingQuery["onboarding"],
  globalOnboardingToggle: boolean | undefined,
): boolean {
  if (!userOnboarding) {
    return false;
  }

  if (userOnboarding && userOnboarding.processStatus === IProcessStatusType.Required && globalOnboardingToggle) {
    return true;
  }

  return false;
}
