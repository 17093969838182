import React from "react";
import { useSelfie } from "~/components/auth/Auth";
import IndexPage from "~/src/components/IndexPage";
import { useRouter } from "next/router";
import LandingPage from "./landingPage";

export default function Index() {
  const user = useSelfie();
  const router = useRouter();
  const previewToken = router.query.preview;
  const isHomePagePreview = typeof previewToken !== "undefined" && previewToken.length > 0;

  if (!user || isHomePagePreview) {
    return <LandingPage />;
  }

  return <IndexPage userId={user._id} />;
}
