import { gql } from "@apollo/client";

export default gql`
  query Onboarding($userId: ObjectId!) {
    onboarding(userId: $userId) {
      _id
      workingFor
      processStatus
      country
      state
      city
      governmentId
      vendorName
      vendorWebsite
      linkedInUrl
      twitterUrl
      facebookUrl
      workplaceName
      workplaceWebsite
      whyYouWantToJoin
    }
  }
`;
